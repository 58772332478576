<template>
  <el-dialog
    title="申请记录详情"
    :visible.sync="dialogVisible2"
    :before-close="handleClose"
    width="1000px"
    height="1000px"
  >
    <!-- 内层弹窗的内容 -->
    <el-dialog
      width="60%"
      title="兴村通平台业务记录"
      :visible.sync="innerVisible"
      append-to-body
      custom-class="dialogTab"
    >
      <!-- 业务记录 -->
      <div class="BusinessRecord">
        <!-- tabs切换 -->
        <div class="tabs">
          <div
            v-for="(item, index) in XingCunTongAllList"
            :key="index"
            @click="TabsBtn(item.flag)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="content">
          <table
            border="1"
            v-for="(item, index) in XingCunTongAllList"
            :key="index"
          >
            <!-- 生产服务 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Production' &&
                  BusinessRecordOptionsIndex == 'Production'
                "
              >
                <th>接管编号</th>
                <th>区域</th>
                <th>接管类型</th>
                <th>价格</th>
                <th>托管人</th>
                <th>托管时间</th>
                <th>接管状态</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Production' &&
                BusinessRecordOptionsIndex == 'Production'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>{{ item.orderCode }}</td>
                <td>{{ item.areaName }}</td>
                <td>{{ item.orderType == "1" ? "全程托管" : "环节托管" }}</td>
                <td>{{ item.estimatePrice }}元/亩</td>
                <td>{{ item.farmerName }}</td>
                <td>{{ item.startDate }}-{{ item.endDate }}</td>
                <td>
                  {{
                    item.orderState == "00"
                      ? "待成交"
                      : item.orderState == "01"
                      ? "托管中"
                      : "托管结束"
                  }}
                </td>
              </tr>
            </tbody>
            <!-- 资源资产 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Resource' &&
                  BusinessRecordOptionsIndex == 'Resource'
                "
              >
                <th>托管编号</th>
                <th>项目名称</th>
                <th>交易方式</th>
                <th>成交时间</th>
                <th>成交价</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Resource' &&
                BusinessRecordOptionsIndex == 'Resource'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>
                  {{ item.xmbh }}
                </td>
                <td>{{ item.xmmc }}</td>
                <td>{{ item.jyfs }}</td>
                <td>{{ item.jjjzsj }}</td>
                <td>{{ item.myBj }}元</td>
              </tr>
            </tbody>
            <!-- 免费记账 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Bookkeeping' &&
                  BusinessRecordOptionsIndex == 'Bookkeeping'
                "
              >
                <th>标题</th>
                <th>文件</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Bookkeeping' &&
                BusinessRecordOptionsIndex == 'Bookkeeping'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>{{ item.title }}</td>
                <td class="col">{{ JSON.parse(item.files)[0].FilesName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
    <div class="LoanDetails">
      <table border="1" cellpadding="0">
        <tbody>
          <tr>
            <td>申请人</td>
            <!-- 点击的时候请求内层弹窗的三个接口 -->
            <td>
              {{ historyList.name
              }}<span @click="InnerBoxBtn()">兴村通业务记录</span>
            </td>
            <td>地址</td>
            <td>{{ historyList.area }}</td>
          </tr>

          <tr>
            <td>身份证号</td>
            <td>{{ historyList.idCardNo }}</td>
            <td>手机号</td>
            <td>{{ historyList.phone }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>年经营收入</td>
            <td>{{ historyList.income }}</td>
            <td>实际经营年限</td>
            <td>{{ historyList.operatYear }}年</td>
          </tr>

          <tr v-for="(item, index) in historyList.operatList" :key="index">
            <td>种养品种</td>
            <td>{{ item.operatCrop }}</td>
            <td>规模</td>
            <td>{{ item.operatCount }}{{ item.operatUnit }}</td>
          </tr>
          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>贷款金额</td>
            <td colspan="3">{{ historyList.money }}</td>
          </tr>

          <tr>
            <td>资金用途</td>
            <td colspan="3">{{ historyList.use }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>

          <tr>
            <td>审批状态</td>
            <td>{{ historyList.status }}</td>
            <td v-if="IsStatus == '1'">申请时间</td>
            <td v-if="IsStatus == '1'">{{ historyList.approveTime }}</td>

            <td v-if="IsStatus == '-1'">驳回原因</td>
            <td v-if="IsStatus == '-1'">{{ historyList.failRemark }}</td>

            <td v-if="IsStatus == '2'">批准时间</td>
            <td v-if="IsStatus == '2'">{{ historyList.approveTime }}</td>
          </tr>
          <tr v-if="IsStatus == '2'">
            <td>批准金额</td>
            <td>{{ historyList.approveMoney }}</td>
            <td>放款账户</td>
            <td>{{ historyList.bankAccount }}</td>
          </tr>
          <tr v-if="IsStatus == '2'">
            <td>账户名</td>
            <td>{{ historyList.bankAccountName }}</td>
            <td>开户行</td>
            <td>{{ historyList.bankName }}</td>
          </tr>
        </tbody>
      </table>

      <!-- 法人身份证 -->
      <div class="LegalPersonIDCard">
        <div class="Left">
          <p class="title"><span>身份证人面像</span></p>
          <img :src="baseUrl +'admin/file/get?ossFilePath=' + historyList.userCardFrontImg" alt="" />
        </div>
        <div class="Right">
          <p><span>身份证国徽像</span></p>
          <img :src="baseUrl +'admin/file/get?ossFilePath=' +historyList.userCardBackImg" alt="" />
        </div>
      </div>
      <!-- 其他附件 -->
      <div class="OtherAccessories">
        <span class="title">其他附件</span>
        <div class="BusinessLicenseItem" v-if="historyList.files">
          <div
            class="Item"
            v-for="(item, index) in JSON.parse(historyList.files)"
            :key="index"
          >
            <el-image
              :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl"
              @click="AddFiles(item)"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
              </div>
            </el-image>
            <span>
              {{ item.FilesName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  operateDetails,
  ProductionService,
  ResourceAssets,
  selectExport,
} from "@/api/RuralFinance";

export default {
  data() {
    return {
      baseUrl: window.globalUrl.BASE_API,
      dialogVisible2: true, //弹窗
      historyList: [], //弹窗内容
      IsStatus: "", //审核状态
      innerVisible: false, //内层弹窗
      identityId: "", //查看用户的identityId
      BusinessRecordOptions: ["生产服务", "资源资产流转", "兴村通免费记账"],
      BusinessRecordOptionsIndex: 0, // 业务记录选项
      XingCunTongAllList: [], // 三大列表综合数据
    };
  },

  mounted() {},

  methods: {
    handleClose(done) {
      this.$emit("closeDialog2", false);
      console.log("是否点击触发");
    },

    //回显详情数据
    async init(applyId, identityId) {
      this.identityId = identityId;
      // console.log(val,"传过来的")
      let params = {
        applyId: applyId,
      };
      let res = await operateDetails(params);
      this.historyList = res.data;
      this.IsStatus = res.data.status;
      if (this.historyList.status == "-1") {
        this.historyList.status = "驳回";
      } else if (this.historyList.status == "0") {
        this.historyList.status = "未提交";
      } else if (this.historyList.status == "1") {
        this.historyList.status = "审批中";
      } else if (this.historyList.status == "2") {
        this.historyList.status = "通过";
      }
      this.historyList.operatList.forEach((item, index) => {
        // console.log(item, "查看");
        if (item.operatUnit == "1") {
          item.operatUnit = "亩";
        } else {
          item.operatUnit = "平方米";
        }
      });
    },
    //打开内层弹窗
    InnerBoxBtn() {
      this.innerVisible = true;
      // console.log("打开内层弹窗");
      //生产资源列表
      this.XingCunTongAllList = [];
      new Promise((resolve, reject) => {
        resolve(this.ProductionService());
        resolve(this.ResourceAssets());
        resolve(this.selectExport()).then((res) => {});
      });
    },
    // 内层弹窗
    TabsBtn(flag) {
      this.BusinessRecordOptionsIndex = flag;
      // console.log(this.BusinessRecordOptionsIndex, "这是下标");
    },

    //生产服务列表
    async ProductionService() {
      let userType = this.historyList.userType; //userType
      let authId = this.identityId; //authId
      // console.log(this.historyList.userId,'查看userId2')
      let params = {
        userType,
        authId,
      };
      // console.log(params);
      let res = await ProductionService(params);
      // this.ProductionServiceList = res.data;
      if (res.data.length) {
        this.XingCunTongAllList.unshift({
          title: "生产服务",
          flag: "Production",
          ServiceList: res.data,
        });
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        // console.log(this.XingCunTongAllList, "生产服务push");
      }
    },
    // 资源资产列表
    async ResourceAssets() {
      let userId = this.historyList.userId; //authId
      let identityId = this.identityId;
      let params = {
        userId: userId,
        identityId: identityId,
        size: 1000,
        current: 1,
      };
      let res = await ResourceAssets(params);
      // this.ResourceAssetsList = res.data.records;
      if (res.data.records.length) {
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        this.XingCunTongAllList.unshift({
          title: "资源资产",
          flag: "Resource",
          ServiceList: res.data.records,
        });
        // console.log(this.XingCunTongAllList, "资源资产push");
      }
    },
    //免费记账
    async selectExport() {
      // let userType = this.historyList.userType; //userType
      let userId = this.historyList.userId; //authId
      let identityId = this.identityId;
      // console.log(identityId, "这是identityId");
      let params = {
        userId: userId,
        identityId: identityId,
      };
      let res = await selectExport(params);
      // this.selectExportList = res.data;
      if (res.data.length) {
        this.XingCunTongAllList.unshift({
          title: "免费记账",
          flag: "Bookkeeping",
          ServiceList: res.data,
        });
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        // console.log(this.XingCunTongAllList, "免费记账push");
      }
    },
    //附件下载功能
    async AddFiles(item) {
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = item.imgUrl;
      dom.setAttribute("download", item.FilesName);
      document.body.appendChild(dom);
      dom.click();
    },
  },
};
</script>

<style lang="less" scoped>
.LoanDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    tr {
      td {
        width: 80px;
        height: 30px;
        text-align: center;
        span {
          background: #169bd5;
          color: white;
          padding: 3px;
          border-radius: 5px;
        }
      }
    }
  }

  /*法人身份证*/
  .LegalPersonIDCard {
    width: 100%;
    display: flex;
    border: solid 1px black;
    border-top: none;
    padding: 5px 0px;
    box-sizing: border-box;
    .Left {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .Right {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  /*其它附件*/
  .OtherAccessories {
    width: 100%;
    display: flex;
    border: solid 1px black;
    border-top: none;
    .title {
      width: 100px;
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-right: 100px;
    }
    .BusinessLicenseItem {
      width: 100%;
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        margin: 0px 5px;
        i {
          font-size: 50px;
        }
      }
      .Item {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        padding: 10px;
        .el-image {
          width: 150px;
          height: 85px;
          background: #e6e3e3;
          img {
            width: 200px;
            height: 50px;
          }
          i {
            font-size: 50px;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        span {
          margin-top: 5px;
        }
        .del {
          width: 20px;
          height: 20px;
          border: solid 1px black;
          border-radius: 50px;
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 5000;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
}
/*业务记录*/
.dialogTab {
  width: 100%;
  height: 100%;
  .el-dialog__body {
    width: 100%;
    height: 100%;
    .BusinessRecord {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .tabs {
        display: flex;
        justify-content: space-evenly;
        div {
          width: 45%;
          text-align: center;
        }
      }
      .content {
        width: 100%;
        margin-top: 20px;
        table {
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          .col {
            text-decoration: underline;
            border-bottom: 10px;
          }
          td {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
