<template>
  <el-dialog
    :title="'申请单' + this.applyId + '状态确认'"
    :visible.sync="dialogVisible3"
    :before-close="handleClose"
    width="1000px"
    height="100%"
  >
    <div class="approval">
      <div class="topApproval">
        <div>
          <span class="Col">申请人：</span> <span>{{ historyList.name }}</span>
        </div>
        <div>
          <span class="Col">证件号：</span>
          <span>{{ historyList.idCardNo }}</span>
        </div>
        <div>
          <span class="Col">申请额度：</span>
          <span>{{ historyList.money }}</span> <span></span>
        </div>
      </div>
      <div class="bottomApproval">
        <div class="dismiss">
          <el-input
            type="textarea"
            placeholder="选填。                                                            可填入驳回原因，帮助申请人更新资料"
            v-model="failRemark"
          >
          </el-input>
          <div class="dismissBtn">
            <el-button v-if="historyList.status=='1'||historyList.status=='-1'" type="primary" @click="dismissBtn()"
              >申请未通过</el-button
            >
          </div>
        </div>
        <div class="pass">
          <div class="form">
            <el-form ref="form" :model="form">
              <el-form-item label="审批额度" prop="seasonYield">
                <el-input
                  v-model="form.approveMoney"
                  placeholder="请输入审批额度"
                ></el-input>
              </el-form-item>
              <!-- :picker-options="pickerOptions" -->
              <el-form-item label="审批时间" prop="startDate">
                <el-date-picker
                  v-model="form.approveTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请输入审批时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="放款账户">
                <el-input
                  v-model="form.bankAccount"
                  placeholder="请输入放款账户"
                ></el-input>
              </el-form-item>
              <el-form-item label="账户名">
                <el-input
                  v-model="form.bankAccountName"
                  placeholder="请输入账户名"
                ></el-input>
              </el-form-item>
              <el-form-item label="开户行">
                <el-input
                  v-model="form.bankName"
                  placeholder="请输入开户行"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="passBtn">
              <el-button v-if="historyList.status=='1'||historyList.status=='2'" type="primary" @click="passBtn()">申请通过</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { FormList, operateDetails, bankUpLoan } from "@/api/RuralFinance";
export default {
  data() {
    return {
      dialogVisible3: true, //弹窗
      historyList: [], //回显的弹窗内容
      failRemark: "", //驳回原因,
      applyId: "", //申请单ID
      form: {
        approveMoney: "", //审批额度
        approveTime: "", //审批时间
        bankAccount: "", //放款账户
        bankAccountName: "", //账户名
        bankName: "", //开户行
      },

    };
  },

  methods: {
    handleClose(done) {
      this.$emit("closeDialog3", false);
    },
    //回显详情数据
    async init(applyId, status) {
      this.applyId = applyId;
      //这是详情接口
      let res = await operateDetails({ applyId: applyId });
      this.historyList = res.data;
      console.log(status,'查看')
      // 如果是审批中
      if (status == 1) {
        console.log( this.historyList ,' 如果是审批中 '),
         (this.form.approveMoney = this.historyList.approveMoney), //审批额度
          (this.form.approveTime = this.historyList.approveTime), //审批时间
          (this.form.bankAccount = this.historyList.bankAccount), //放款账户
          (this.form.bankAccountName = this.historyList.bankAccountName), //账户名
          (this.form.bankName = this.historyList.bankName); //开户行
      } else {
           //如果不是审批中
              //此接口为修改回显ech
          let res = await FormList({ applyId: applyId });
           let obj = res.data[0];
          console.log(JSON.parse(JSON.stringify(obj)),'查看这是什么2'),
          (this.form.approveMoney =  obj.approveMoney), //审批额度
          (this.form.approveTime = obj.approveTime), //审批时间
          (this.form.bankAccount =  obj.bankAccount), //放款账户
          (this.form.bankAccountName = obj.bankAccountName), //账户名
          (this.form.bankName =  obj.bankName); //开户行
           this.failRemark =obj.failRemark; //驳回原因
      }
      // 1.调详情接口，如果是审批中调新接口进行回显，如果不是审批中，直接展示详情中的信息
    },
    //申请驳回
    async dismissBtn() {
      try {
        let params = {
          status: "-1",
          failRemark: this.failRemark,
          applyId: this.applyId,
        };
        let res = await bankUpLoan(params);
        this.$message.success("审批成功");
        this.$emit("closeDialog3", false);
      } catch (err) {
        this.$message.info("未审批成功");
        this.$emit("closeDialog3", false);
      }
    },
    //申请通过
    async passBtn() {
      try {
        let params = {
          status: "2",
          approveMoney: this.form.approveMoney, //审批额度
          approveTime: this.form.approveTime, //审批时间
          bankAccount: this.form.bankAccount, //放款账户
          bankAccountName: this.form.bankAccountName, //账户名
          bankName: this.form.bankName, //开户行
          applyId: this.applyId,
        };
        let res = await bankUpLoan(params);
        this.$message.success("审批成功");
        this.$emit("closeDialog3", false);
      } catch (err) {
        this.$message.info("未审批成功");
        this.$emit("closeDialog3", false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.approval {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .topApproval {
    width: 100%;
    height: 50px;
    background: #81d3f8;
    display: flex;
    justify-content: space-around;
    .Col {
      font-weight: bold;
      line-height: 50px;
    }
  }
  .bottomApproval {
    width: 100%;
    height: 90%;
    display: flex;
    padding-top: 20px;
    .dismiss {
      width: 50%;
      height: 350px;
      display: flex;
      flex-direction: column;

      /deep/.el-textarea {
        height: 295px;
        textarea {
          height: 288px;
          .el-textarea__inner {
            height: 288px;
          }
        }
      }
      .dismissBtn {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-around;
        padding-top: 18px;
      }
    }
    .pass {
      width: 50%;
      height: 350px;
      /deep/.el-form {
        border-left: solid 1px #ccc;
        margin-left: 10px;
      }
      /deep/.el-form-item {
        width: 100% !important;
        display: flex;
        .el-input {
          width: 260px;
        }
      }
      /deep/.el-form-item__label {
        width: 20%;
      }
      .passBtn {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
      }
    }
  }
}
</style>
